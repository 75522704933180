













































import Base from '@/mixins/Base.vue';
import { IRole, IRoleResponse } from '@/interfaces/role';

const component = Base.extend({
    data() {
        return {
            active: false,

            confirmation: '',
            role: undefined as any | IRole,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.role.name;
        },
    },
    mounted() {
        this.active = true;
        this.getRole();
    },
    methods: {
        getRole(): void {
            this.get<IRoleResponse>(`roles/${this.$route.params.id}`)
                .then(({ data }) => {
                    this.role = data.data;
                });
        },
        save(): void {
            if (this.validated) {
                this.delete(`roles/${this.role.id}`).then(() => {
                    this.$router.push({ name: 'roles.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
